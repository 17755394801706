import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useRouter } from 'next/router';

import { getMachinery } from 'models/machinery';
import { newsletterFormScrollRef } from 'components/partials/footer/FooterForm';
import { MACHINE_STATE } from 'constants/common';

const AuctionDropDown = () => {
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState({});
  const [activeAuctionsCount, setActiveAuctionsCount] = useState(0);
  const ref = useRef();
  const router = useRouter();

  const handleClickOutside = (event) => {
    if (ref && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    getMachineryFromApi();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getMachineryFromApi = async () => {
    try {
      const { machines, filterLookup } = await getMachinery(`state=${MACHINE_STATE.WAITING_EVENT_DATE}`);

      if (!_.isEmpty(machines)) {
        const groupedBy = _.chain(machines).sortBy('startingDate').filter((machine) => moment().isBefore(moment(machine.startingDate))).groupBy((machine) => moment(machine.startingDate).format('DD MMMM'))
          .value();
        setList(groupedBy);
      }

      setActiveAuctionsCount(filterLookup.SALE_STATES.IN_EVENT + filterLookup.SALE_STATES.WAITING_EVENT_DATE);
    } catch (error) {
      console.log('getMachineryFromApiErr', error);
    }
  };

  return (
    <div
      ref={ref}
      className={`dropdown im-dropdown ${visible && 'show'}`}
      onClick={() => setVisible(!visible)}
    >
      <button
        type="button"
        className="im-button im-button--transparent-white im-dropdown__button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-overlay="click"
      >
        <div className="im-button__icon im-button__icon--left">
          <i className="fal fa-calendar-alt" />
        </div>
        <span>Yaklaşan Açık Artırmalar</span>
        <div className="im-button__icon im-button__icon--right im-dropdown__button__arrow">
          <i className="fal fa-angle-down" />
        </div>

      </button>
      <div className={`dropdown-menu dropdown-menu-right ${visible && 'show'}`}>
        <div className="dropdown-item only-padding-bottom-15">
          {
            _.map(Object.keys(list), (value) => (
              <div
                className="dropdown-item-inner-item no-gutters"
                key={value}
                onClick={() => router.push({
                  pathname: '/machinery',
                  query: {
                    state: MACHINE_STATE.WAITING_EVENT_DATE,
                  },
                })}
              >
                <div className="col">
                  <span className="fw-900">{value}</span>
                </div>
                <div className="col">
                  <span className="fw-500">{`( ${list[value].length} makina )`}</span>
                </div>
              </div>
            ))
          }
          <a
            className="dropdown-item-inner-item no-gutters"
            onClick={() => router.push({
              pathname: '/machinery',
              query: {
                state: MACHINE_STATE.IN_EVENT,
              },
            })}
          >
            <div className="col">
              <span className="fw-900">Aktif Açık Artırmalar</span>
            </div>
            <div className="col">
              <span className="fw-500">{`( ${activeAuctionsCount} makina )`}</span>
            </div>
          </a>
        </div>
        {newsletterFormScrollRef?.current && (
          <a
            className="dropdown-item fs-13 fw-900 text-gray7"
            onClick={() => newsletterFormScrollRef?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })}
          >
            Gelecek Açık Artırmalardan Haberdar Ol
          </a>
        )}
      </div>
    </div>
  );
};

export default AuctionDropDown;
