/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import _ from 'lodash';

import socketInstance from 'utils/socket.io';
import { getUserDeposit } from 'models/payment';
import { withTranslation } from 'language/i18n';
import maskAmount from 'utils/maskAmount';
import { StatusContext } from 'contexts/statusContext';
import { SOCKET_EMIT, SOCKET_LISTEN } from 'constants/common';
import LimitIncreaseModal from './limitIncreaseModal';

const UserDropDown = ({ t, user, logout }) => {
  const { statusData } = useContext(StatusContext);
  const ref = useRef();

  const [visible, setVisible] = useState(false);
  const [limitIncreaseModalVisible, setLimitIncreaseModalVisible] = useState(false);
  const [userDepositBalance, setUserDepositBalance] = useState(0);
  const [userBuyingLimit, setUserBuyingLimit] = useState(0);
  const handleClickOutside = (event) => {
    if (ref && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    const { socket } = socketInstance;
    if (!_.isEmpty(socket) && statusData.socketConnected) {
      socket.emit(SOCKET_EMIT.JOIN_ROOM, user.id);

      const onBuyingLimit = () => {
        console.log(`Socket event received: ${SOCKET_LISTEN.BUYING_LIMIT}`);
        getUserDepositFromApi();
      };
      socket.on(SOCKET_LISTEN.BUYING_LIMIT, onBuyingLimit);

      return () => {
        socket.emit(SOCKET_EMIT.LEAVE_ROOM, user.id);
        socket.off(SOCKET_LISTEN.BUYING_LIMIT, onBuyingLimit);
      };
    }

    return () => {};
  }, [statusData.socketConnected]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    getUserDepositFromApi();
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getUserDepositFromApi = async () => {
    try {
      const { user_buying_limit, user_deposit_balance } = await getUserDeposit();
      setUserDepositBalance(user_deposit_balance);
      setUserBuyingLimit(user_buying_limit);
    } catch (error) {
      console.log('getUserDepositFromApiErr', error);
    }
  };

  return (
    <div className="header-nav" ref={ref}>
      <div className={`dropdown im-dropdown ${visible ? 'show' : ''}`}>
        <button type="button" className="im-button im-button--transparent-white im-dropdown__button" onClick={() => setVisible(!visible)}>
          <span>{`${t('welcome')}, ${user.name} ${_.result(user, 'surname[0]')}.`}</span>
          <div className="im-button__icon im-button__icon--right im-dropdown__button__arrow">
            <i className="fal fa-angle-down" />
          </div>
        </button>
        <div
          className={`dropdown-menu dropdown-menu-right ${visible ? 'show' : ''}`}
        >
          <div className="dropdown-item dropdown-item-text only-padding-bottom-15 striped-inner-items">

            <div className="dropdown-item-inner-item align-items-center padding-15">
              <div className="col">
                <div className="fw-900 mb-1">{`${user.name} ${user.surname}`}</div>
                <div className="fs-14">{user.email}</div>
              </div>
              <div className="col text-right">
                <Link href="/profile">
                  <a
                    className="im-button im-button--center im-button--gutter-10 im-button--mw-110 im-button--size-35 im-button--gray4 fs-13 fw-500"
                    onClick={() => setVisible(false)}
                  >
                    {t('goProfile')}
                  </a>
                </Link>
              </div>
            </div>
            <div className="dropdown-item-inner-item align-items-center padding-15">
              <div className="col">
                <div className="row flex-nowrap align-items-center">
                  <div className="col-auto pr-0">
                    <svg className="wh-30">
                      <use xlinkHref="/image/icons.svg#ic-auction2" />
                    </svg>
                  </div>
                  <div className="col">
                    <div className="fw-900 fs-18 mb-1">{maskAmount(userBuyingLimit)}</div>
                    <div className="fs-14">Teklif Üst Limitiniz</div>
                  </div>
                </div>
              </div>
              <div className="col text-right">
                {userDepositBalance > 0
                  ? (
                    <a
                      className="im-button im-button--center im-button--gutter-10 im-button--mw-110 im-button--size-35 im-button--outline-yellow2 fs-13 fw-500"
                      onClick={() => {
                        setVisible(false);
                        setLimitIncreaseModalVisible(true);
                      }}
                    >
                      Limiti Artır
                    </a>
                  ) : (
                    <Link href="/payment/deposit">
                      <a
                        className="im-button im-button--center im-button--gutter-10 im-button--mw-110 im-button--size-35 im-button--outline-yellow2 fs-13 fw-500"
                        onClick={() => setVisible(false)}
                      >
                        Teminat Bedeli Yatır
                      </a>
                    </Link>
                  )}
              </div>
            </div>

          </div>
          <div className="dropdown-item dropdown-item-text fs-13 fw-900 text-gray7">
            <a
              className="im-button im-button--fluid im-button--center im-button--transparent im-button--auto-height fs-14 text-black1"
              onClick={() => logout()}
            >
              {t('logout')}
            </a>
          </div>
        </div>
      </div>
      <LimitIncreaseModal
        visible={limitIncreaseModalVisible}
        setVisible={setLimitIncreaseModalVisible}
      />
    </div>
  );
};

UserDropDown.propTypes = {
  t: PropTypes.func,
  user: PropTypes.object,
  logout: PropTypes.func,
};

export default withTranslation('common')(UserDropDown);
