/* eslint-disable consistent-return */
import request from 'utils/request';
import _ from 'lodash';

export const getMachinery = async (filter) => {
  const data = await request(`api/v1/machines${filter ? `?${filter}` : ''}`, 'get');
  return data;
};

export const getSampleMachinery = async (count) => {
  const data = await request(`api/v1/machines/sample?sample=${count}`, 'get');
  return data;
};

export const getSoldMachinery = async () => {
  const data = await request('api/v1/machines/soldMachines', 'get');
  return data;
};

export const getInEventMachinery = async () => {
  const data = await request('api/v1/machines/inEvent', 'get');
  return data;
};

export const getSoonMachinery = async () => {
  const data = await request('api/v1/machines/soon', 'get');
  return data;
};

export const getMachine = async (id) => {
  const data = await request(`api/v1/machines/${id}`, 'get');
  return data;
};

export const getMachineReport = async (id) => {
  const data = await request(`api/v1/machines/${id}/report`, 'get');
  return data;
};

export const filterMachinery = ({ name, value, oldFilters }) => {
  const newFilter = { ...oldFilters };
  if (name === 'sort' || name === 'format' || name === 'state') {
    newFilter[name] = [value];
  } else {
    const item = _.split(name, '_');

    if (newFilter[item[0]]) {
      if (value !== false) {
        newFilter[item[0]].push(item[1]);
      } else {
        const filterIndex = _.indexOf(newFilter[item[0]], item[1]);
        if (filterIndex !== -1) {
          newFilter[item[0]].splice(filterIndex, 1);
        }
      }
    } else if (value === true) {
      newFilter[item[0]] = [item[1]];
    }
  }

  return newFilter;
};

export const getUserPurchase = async (machineId) => {
  const data = await request(`api/v1/user/${machineId}/purchase`, 'get');
  return data;
};

export const saleRequest = async ({
  country,
  region,
  district,
  postalCode,
  brand,
  machineType,
  model,
  serialNumber,
  rate,
  workingHours,
  machineYear,
  customerName,
  customerSurname,
  customerMail,
  customerPhone,
  customerCountry,
  customerIdentificationNumber,
  customerTitle,
  customerCompanyName,
  customerTaxOffice,
  customerTaxNumber,
  customerLearnedFrom,
  customerMachineParkSize,
}) => {
  const { saleRequest: data } = await request('api/v1/form/saleRequest', 'post', {
    body: JSON.stringify({
      country,
      region,
      district,
      postalCode,
      brand,
      machineType,
      model,
      serialNumber,
      rate,
      workingHours,
      machineYear,
      customerName,
      customerSurname,
      customerMail,
      customerPhone,
      customerCountry,
      customerIdentificationNumber,
      customerTitle,
      customerCompanyName,
      customerTaxOffice,
      customerTaxNumber,
      customerLearnedFrom,
      customerMachineParkSize,
    }),
  });
  return data;
};

export const getResendVerficationCode = async (requestId) => {
  const data = await request(`api/v1/form/saleRequest/resendVerficationCode/${requestId}`, 'get');
  return data;
};

export const saleOtpRequest = async (requestId, otpCode) => {
  const data = await request(`api/v1/form/saleRequest/${requestId}`, 'post', {
    body: JSON.stringify({
      otpCode,
    }),
  });
  return data;
};
